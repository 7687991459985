












import { API } from "@/shared/api";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class UserAvatar extends Vue {
  @Prop() id_user!: number;
  public image_default = false;
  public get getImagen() {
    return API.webApiBaseImages + this.id_user + ".png";
  }
  public get createImagen() {
    return API.TestAvatar + "/" + this.id_user;
  }
  public imageLoadError(event: any) {
    this.image_default = true;
  }
}
